/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LogRocket from 'logrocket';
import FullPageRoutes from './routes/FullPageRoutes';
import NoFooterRoute from './routes/NoFooterRoute';
import SignUpLandingPage from 'app/pages/SignUpLandingPage/Loadable';
import ContactPage from 'app/pages/ContactPage/Loadable';
import Thankyou from 'app/pages/ContactPage/Thankyou/Loadable';
import CareersPage from 'app/pages/CareersPage/Loadable';
import FAQPage from 'app/pages/FaqPage/Loadable';
import HomePage from 'app/pages/HomePage/Loadable';
import MapPage from 'app/pages/MapPage/Loadable';
import PrivacyPage from 'app/pages/PrivacyPage/Loadable';
import NotFoundPage from 'app/pages/NotFoundPage/Loadable';
import TermConditionPage from 'app/pages/TermConditionPage/Loadable';
import CreatorMapPage from './pages/CreatorMapPage/Loadable';
import CreatorMapPageV2 from './pages/CreatorMapPageV2/Loadable';

import UserProfliePage from './pages/UserProfliePage/index';
import CreatorProfliePage from './pages/CreatorProfliePage/index';

export function App(props: any) {
  const { i18n } = useTranslation();
  LogRocket.init('334xtb/travelsighted');
  return (
    <>
      <Helmet
        titleTemplate="%s - Travelsighted"
        defaultTitle="Travelsighted"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta
          name="description"
          content="Travel site that makes you travelsighted.  See the places you are going to you can really see the places you are going."
        />
      </Helmet>

      <Routes>
        <Route path="/" element={<SignUpLandingPage />} />
        <Route
          path="/creator/Map/:creatorid/:city"
          element={<CreatorMapPage />}
        />
        <Route
          path="/creator/MapV2/:creatorid/:city"
          element={<CreatorMapPageV2 />}
        />

        <Route element={<FullPageRoutes />}>
          <Route path="/home" element={<HomePage />} />
          <Route path="/contact/thankyou" element={<Thankyou />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/termcondition" element={<TermConditionPage />} />
          <Route path="/user/profile/:id" element={<UserProfliePage />} />
          <Route path="/creator/profile/:id" element={<CreatorProfliePage />} />
          <Route element={<NotFoundPage />} />
        </Route>
        <Route element={<NoFooterRoute />}>
          <Route path="/map/:city" element={<MapPage />} />
          <Route path="/map/:city/:placeId/:clipId" element={<MapPage />} />
        </Route>
      </Routes>
    </>
  );
}
