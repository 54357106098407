/**
 *
 * Footer
 *
 */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { messages } from './messages';
import { useTranslation } from 'react-i18next';
import { Grid, Cell } from 'styled-css-grid';
import { Icon } from 'semantic-ui-react';
import FooterTextInput from '../FooterTextInput/index';
import { useDispatch, useSelector } from 'react-redux';
import {
  wishListSubmittedSuccess,
  wishListSubmittedLoading,
  wishListSubmittedError,
} from './slice/selectors';
import { useFooterSlice } from './slice';
interface Props {}
export default function Footer(props: Props) {
  const { t } = useTranslation();

  const StyledFooter = styled.div`
    .mainDiv {
      padding: 30px 40px 40px 40px;
      background-color: #242329;
      font-variant-caps: all-small-caps;
    }
    .travelsighted_txt {
      color: white;
      font-size: xx-large;
      font-weight: bold;
    }
    .travelsighted_txt:hover {
      color: #cce2ff;
    }
    .secondRowDiv {
      margin-top: 3px;
    }
    .thirdRowDiv {
      margin-top: 0px;
    }
    .fourthRowDiv {
      margin-top: 2px;
    }
    .txt {
      color: white;
      font-weight: normal;
      line-height: 1;
    }
    .link {
      color: white;
      font-weight: normal;
      line-height: 1;
    }
    .link:hover {
      color: #cce2ff;
    }
    .horizontalLine {
      color: white;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .txtInput {
      width: 250px;
      margin-right: 20px;
    }
    .inputTextDiv {
      flex-direction: 'column';
    }
    .socailMediaIcons {
      color: white;
    }
    .socailMediaIcons:hover {
      color: #cce2ff;
    }

    .ui.button {
      padding: 10px;
      height: 38px;
    }
    .socialMediaDiv {
      flex-direction: 'column';
    }
    .fitContent {
      width: 'fit-content';
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-family: 'Encode Sans SC', sans-serif;
      font-family: 'Roboto', sans-serif;
      font-size: large;
    }
    @media only screen and (max-width: 1040px) {
      .ui.primary.button {
        margin-top: 10px;
      }
    }

    @media only screen and (max-width: 525px) {
      .txt {
        color: white;
        font-weight: normal;
        font-size: 80%;
      }
    }
    @media only screen and (max-width: 375px) {
      .travelsighted_txt {
        color: white;
        font-size: small;
      }
      .txt {
        color: white;
        /* font-weight: normal; */
        font-size: xx-large;
      }
      .txtInput {
        height: 20px;
        width: 200px;
      }
      .ui.button {
        padding: 4px;
        height: 15px;
        font-size: 10px;
      }
    }
    @media only screen and (max-width: 414px) {
      .travelsighted_txt {
        color: white;
        font-size: small;
      }
      .txt {
        color: white;
        font-weight: normal;
        font-size: 60%;
      }
      .txtInput {
        height: 20px;
        width: 200px;
      }
      .ui.button {
        padding: 4px;
        height: 20px;
        font-size: 10px;
      }
    }
    @media only screen and (max-width: 320px) {
      .travelsighted_txt {
        color: white;
        font-size: small;
      }
      .txt {
        color: white;
        font-weight: normal;
        font-size: 60%;
      }
      .txtInput {
        width: 200px;
        height: 25px;
      }
      i.icon.instagram:before {
        content: '\f16d';
        font-size: 16px;
      }
      i.icon.youtube:before {
        content: '\f167';
        font-size: 16px;
      }
      i.icon.twitter:before {
        content: '\f099';
        font-size: 16px;
      }
    }
  `;
  const currentDate = new Date();
  const youtubeUrl: string =
    'https://www.youtube.com/channel/UCDZb0M_d7819NPNj9VaVmtw';
  const instagrameUrl: string = 'https://www.instagram.com/travelsightedapp/';

  const { actions } = useFooterSlice();
  const dispatch = useDispatch();
  const success = useSelector(wishListSubmittedSuccess);
  let loading = useSelector(wishListSubmittedLoading);
  let error = useSelector(wishListSubmittedError);
  const onSubmit = formData => {
    dispatch(actions.insertingFooterCity(formData));
  };

  return (
    <StyledFooter>
      <Grid columns={1}>
        <Cell width={1}>
          <div className="mainDiv">
            {/*First Row */}
            <div>
              <Grid columns={12}>
                <Cell width={12}>
                  <h1 className="fitContent">
                    <Link to="/" className={'travelsighted_txt'}>
                      <p>{t(...messages.Title())}</p>
                    </Link>
                  </h1>
                </Cell>
              </Grid>
            </div>

            {/*Second Row */}

            <div className={'secondRowDiv'}>
              <Grid columns={12}>
                <Cell width={2}>
                  <h5 className="txt">{t(...messages.Creator())}</h5>
                </Cell>
                <Cell width={2}></Cell>
                <Cell width={3}>
                  <h5 className="txt">{t(...messages.AddCreator())}</h5>
                </Cell>
                <Cell width={1}></Cell>
                <Cell width={2}>
                  <div className="socialMediaDiv">
                    <h5 className="txt">{t(...messages.FollowUs())}</h5>

                    <a
                      href={instagrameUrl}
                      target="_blank"
                      title="instagram url"
                      rel="noreferrer"
                    >
                      <Icon
                        className="socailMediaIcons"
                        name="instagram"
                        size="large"
                      />
                    </a>
                    <a
                      href={youtubeUrl}
                      target="_blank"
                      rel="noreferrer"
                      title="Youtube Link"
                    >
                      <Icon
                        className="socailMediaIcons"
                        name="youtube"
                        size="large"
                      />
                    </a>
                  </div>
                </Cell>

                <Cell width={2}>
                  <h5 className="fit-content">
                    <Link to="/contact" className="link">
                      {t(...messages.ContactUs())}
                    </Link>
                    <br />
                    {/* <Link to="/creators" className="link">
                      {t(...messages.CreatorLink())}
                    </Link> */}
                  </h5>
                </Cell>
              </Grid>
            </div>

            {/*Third Row */}

            <div className="thirdRowDiv">
              <Grid columns={12}>
                <Cell width={4}></Cell>
                <Cell width={4}>
                  <div className="inputTextDiv">
                    <FooterTextInput
                      onSubmit={onSubmit}
                      success={success}
                      loading={loading}
                      error={error}
                    />
                  </div>
                </Cell>
                <Cell width={4} center={true}></Cell>
              </Grid>
            </div>

            <hr className="horizontalLine" />

            {/*Fourth Row */}

            <Grid columns={12}>
              <Cell width={6}>
                <h5 className="txt">
                  © {currentDate.getFullYear()} {t(...messages.CopyRight())}
                </h5>
              </Cell>

              <Cell width={2}>
                <h5 className="fit-content">
                  <Link to="/privacy" className="link">
                    {t(...messages.Privacy())}
                  </Link>
                </h5>
              </Cell>

              <Cell width={4} center={true}>
                <h5 className="fit-content">
                  <Link to="/termcondition" className="link">
                    {t(...messages.TermCondition())}
                  </Link>
                </h5>
              </Cell>
            </Grid>
          </div>
        </Cell>
      </Grid>
    </StyledFooter>
  );
}
