/**
 *
 * Asynchronously loads the component for CreatorMapPageV2
 *
 */

import { lazyLoad } from 'utils/loadable';

const CreatorMapPageV2 = lazyLoad(
  () => import('./index'),
  module => module.CreatorMapPageV2,
);

export default CreatorMapPageV2;
