import React from 'react';
import { Provider } from 'react-redux';
import { CssBaseline } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalStyle } from 'styles/global-styles';
// import { ThemeProvider } from 'styles/theme/ThemeProvider';

const ProviderWrapper: any = ({ children, store }) => (
  <Provider store={store}>
    <>
      <CssBaseline />
      <Router>{children}</Router>
      <GlobalStyle />
    </>
  </Provider>
);

export default ProviderWrapper;
