/**
 *
 * CreatorProfliePage
 *
 */
import * as React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import PageHeaderImage from 'app/components/PageHeaderImage';
import { useCreatorProfilePageSlice } from './slice';
import { selectCreatorProfile } from './slice/selectors';
import { creatorProfile } from 'types/Shared';

interface Props {}

export default function CreatorProfliePage(props: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();

  //Supporting variables
  const { actions } = useCreatorProfilePageSlice(),
    match = useParams(),
    dispatch = useDispatch();

  //Rdux state variables
  const creatorProfile: creatorProfile | undefined =
    useSelector(selectCreatorProfile);

  React.useEffect(() => {
    const userId: number = (match['id'] as unknown as number) ?? 0;
    dispatch(actions.loadCreatorProfile(userId));
  }, [dispatch, actions, match]);

  return (
    <>
      <Helmet>
        <title>Creator Profile Page</title>
        <meta name="description" content="User Profile Page" />
      </Helmet>
      <PageHeaderImage city={''} />
      <Wrapper>
        {creatorProfile === undefined ? (
          <h1>Loading</h1>
        ) : (
          <div>
            <div className="makeRow">
              <div>
                <h1>id: </h1>
              </div>
              <div>
                <h1>{creatorProfile.id}</h1>
              </div>
            </div>

            <div className="makeRow">
              <div>
                <h1>description: </h1>
              </div>
              <div>
                <h1>{creatorProfile.description}</h1>
              </div>
            </div>

            <div className="makeRow">
              <div>
                <h1>image: </h1>
              </div>

              <div>
                <h1>{creatorProfile.profileImage}</h1>
              </div>
            </div>

            <h1 className="citiesList">
              ************* Cities list *************
            </h1>

            {creatorProfile?.cities.map((item: any) => {
              return (
                <div className="citiesDiv">
                  <div className="makeRow">
                    <div>
                      <h1>city: </h1>
                    </div>
                    <div>
                      <h1>{item.city}</h1>
                    </div>
                  </div>

                  <div className="makeRow">
                    <div>
                      <h1>cityId: </h1>
                    </div>
                    <div>
                      <h1>{item.cityId}</h1>
                    </div>
                  </div>

                  <div className="makeRow">
                    <div>
                      <h1>numberOfPlaces: </h1>
                    </div>
                    <div>
                      <h1>{item.numberOfPlaces}</h1>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  min-height: 100%;
  .makeRow {
    display: flex;
    flex-direction: row;
  }
  .citiesDiv {
    margin-top: 10vh;
  }
  .citiesList {
    margin-top: 10vh;
  }
`;
