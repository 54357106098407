/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as React from 'react';
import ReactDOM from 'react-dom/client';

// Use consistent styling
import 'sanitize.css/sanitize.css';
// Import root app
import { App } from 'app';
import { HelmetProvider } from 'react-helmet-async';
import { configureAppStore } from 'store/configureStore';

import reportWebVitals from 'reportWebVitals';

import { Cookies, getCookieConsentValue } from 'react-cookie-consent';
import { CookieComponent } from './app/components/CookieComponent/Loadable';
// Initialize languages
import './locales/i18n';

import ProviderWrapper from 'ProviderWrapper';
import GA4React from 'ga-4-react';

// // Observe loading of Inter (to remove 'Inter', remove the <link> tag in
// // the index.html file and this observer)
// const openSansObserver = new FontFaceObserver('Inter', {});

// // When Inter is loaded, add a font-family using Inter to the body
// openSansObserver.load().then(() => {
//   document.body.classList.add('fontLoaded');
// });

const ga4react = new GA4React(
  process.env.REACT_APP_GOOGLE_ANALYTICS_KEY as string,
);

const store = configureAppStore();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

(async () => {
  try {
    const isConsent = getCookieConsentValue();
    if (isConsent !== 'false') {
      await ga4react.initialize();
    } else {
      Cookies.remove('_ga');
      Cookies.remove(
        ('_ga_' +
          process.env.REACT_APP_GOOGLE_ANALYTICS_KEY_FOR_COOKIE) as string,
      );
    }
  } catch (error) {}

  root.render(
    <ProviderWrapper store={store}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
      <CookieComponent />
    </ProviderWrapper>,
  );
})();

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
