/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from 'app/components/Header';
import Footer from 'app/components/Footer';
import Page from 'app/components/layout/Page';

const FullPageRoutes = () => (
  <Page>
    <Header showCityLink />
    <Outlet />
    <Footer />
  </Page>
);

export default FullPageRoutes;
